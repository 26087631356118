.simulator {
    width: 100%;
    height: 100%;
    position: absolute;
    margin: auto;
}

.simulatorIcons {
    position: absolute;
    right: 5%;
    bottom: 5%;
    height: 30%;
    width: 5%;
}

.simulatorIcon {
    position: relative;
    height: 25%;
}

.individual {
    position: relative;
    width: 100%;
    height: 70%;
}
