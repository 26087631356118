.slider_icon_left {
    position: absolute;
    z-index: 99;
    right: 70%;
    top: 80px;
    height: 80px;
    width: 20px;
    animation: 0.1s linear 0s 1 alternate slide-in;
}

.slider_icon_right {
    position: absolute;
    z-index: 99;
    right: 0%;
    top: 80px;
    height: 80px;
    width: 20px;
}

#overlay {
    z-index: 90;
    background: none;
    position: absolute;
    height: 100vh;
    width: 100vw;
}

.icon_box {
    background: white;
    position: absolute;
    height: 100%;
    width: 100%;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    box-shadow: -2.5px 0px 1.5px grey;
}

.arrow {
    position: absolute;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
}

.slider_container {
    z-index: 95;
    position: absolute;
    height: calc(100% - 40px);
    width: 70%;
    right: 0%;
    bottom: 0%;
    animation: 0.1s linear 0s 1 alternate slide-in;
}

.code_container {
    z-index: 95;
    position: absolute;
    height: calc(100% - 40px);
    width: 50%;
    right: 0%;
    bottom: 0%;
    overflow: scroll;
}

@keyframes slide-in {
    from {
        transform: translateX(70%);
    }

    to {
        transform: translateX(0);
    }
}

.container_box {
    background: white;
    position: absolute;
    height: 100%;
    width: 100%;
    right: 0%;
    bottom: 0%;
    box-shadow: -2.5px 0px 2.5px grey;
}

code {
    height: 100%;
    width: 100%;
    right: 0%;
    bottom: 0%;
}

.line-numbers .line-numbers-rows {
    position: absolute;
    pointer-events: none;
    top: 0;
    width: 3em;
    /* works for line-numbers below 1000 lines */
    letter-spacing: -1px;
    border-right: 1px solid #999;
    user-select: none;
}

.line-numbers-rows > span {
    counter-increment: linenumber;
}

.line-numbers-rows > span:before {
    content: counter(linenumber);
    color: #999;
    display: block;
    padding-right: 0.8em;
    text-align: right;
}
