.logoutButton{
    font-size:1vw;
    width: 10vw;
    height: 2.5vh;
    position: absolute;
    top: 0; 
    left: 0;
}



.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
}

.card {
    background-color: white;
    padding: 60px;
    border-radius: 12px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: auto;
    max-width: 800px; 
    text-align: center;
    margin: 20px; 
}
.google-signin-btn {
    background-color: #64b30a;
    color: white;
    padding: 10px 24px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    display: inline-block;
    transition: background-color 0.3s;
}

.google-signin-btn:hover {
    background-color: #3e7004;
}


@media (max-width: 768px) {
    .card {
        padding: 40px; 
        max-width: 90%; 
    }

    .google-signin-btn {
        padding: 10px 24px; 
        font-size: 16px; 
    }
}